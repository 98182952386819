<template >
  <div id="questionsvideo">
    <div fluid>
      <v-row v-if="activeVideo && activeVideo.downloadVideoUrl">
        <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
          <v-responsive class="overflow-y-hidden fill-height" height="470">
            <!-- <p class="body-2" v-if="!activeVideo.highlights">Nothing to show</p> -->
            <v-list>
              <v-list-item
                v-for="question in activeVideo.highlights"
                :key="question.id"
                @click="selectQuestion(question)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="question.text"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey">mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-responsive>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-row style="height: 200px;">
            <div>
              <h2 class="font-weight-bold headline pb-2">Impossibile visualizzare le domande e risposte</h2>
              <p
                class="font-weight-light body-2 pb-5"
              >Sottoscrivi a Techsoup Together per sbloccare funzionalitá</p>
              <!-- <v-btn color="primary" big>INIZIA ORA</v-btn> -->
              <UpgradeButton big />
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import UpgradeButton from "@/components/UpgradeButton.vue";

export default {
  name: "QuestionsVideo",

  data() {
    return {
      highlights: []
    };
  },

  components: {
    UpgradeButton
  },

  computed: {},

  props: {
    activeVideo: {
      default: () => ({})
    }
  },

  methods: {
    selectQuestion(question) {
      this.$emit("changePlayTime", question.time);
    }
  },

  created() {}
};
</script>

<style scoped>
</style>