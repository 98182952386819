<template>
  <div>
      <v-btn
        @click="openPricingModal"
        color="primary"
        v-if="user && ps && ps.length == 0"
        >UPGRADE ORA</v-btn>
      <v-btn @click="openBillingPortal" color="primary" v-else
        >GESTISCI FATTURAZIONE</v-btn
      > 
    <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
    />

    <!-- Prices modal -->
    <PricingPlans v-model="pricesDialogOpen" />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import axios from "axios";
import { fb } from "@/plugins/firebase.js";

export default {
  name: "UpgradeButton",

  components: {
    OverlayLoading: () => import("@/components/OverlayLoading"),
    PricingPlans: () => import("@/components/misc/PricingPlans"),
  },

  data() {
    return {
      stripe_error: null,
      sessionId: "",
      inProgress: false,
      overlaymessage: "Inizializzando una connessione sicura. Attendere prego.",
      overlaymessage2: "",

      pricesDialogOpen: false,
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      prices: "prices",
      ps: "ps",
    }),
  },
  methods: {
    openPricingModal() {
      this.pricesDialogOpen = true;
      
      const pricesDuplicated = JSON.parse(JSON.stringify(this.prices));
      
      let indexproduct = 0

      function iterate(item) {
        indexproduct = indexproduct + 1
        // console.log('new index -->> ' + indexproduct)

        item.item_id = item.id
        item.item_category = item.interval
        item.quantity = 1,
        item.price = item.unit_amount / 100,
        item.item_name = item.interval + 'ly ' + item.unit_amount / 100,
        item.index = indexproduct
        delete item.active;
        delete item.currency;
        delete item.description;
        delete item.id;
        delete item.interval;
        delete item.interval_count;
        delete item.trial_period_days;
        delete item.type;
        delete item.unit_amount;

        // console.log(item)
      }     

      pricesDuplicated.forEach(iterate);

      // send to GA4 events for product impressions 
      // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#measure_product_impressions
      this.$gtag.query('event', 'view_item', {
        currency: 'EUR',
        items: [pricesDuplicated[0]]
      });

      this.$gtag.query('event', 'view_item', {
        currency: 'EUR',
        items: [pricesDuplicated[1]]
      });

    },
    
    async openBillingPortal() {
      this.inProgress = true;

      const functionRef = fb.app().functions("europe-west2").httpsCallable(
        "ext-firestore-stripe-subscriptions-createPortalLink"
      );
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);

      // GA4 event: open billing portal
      this.$gtag.query('event', 'billing_portal_open');
    },
  },
};
</script>

<style>
</style>